import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import WgptBotSourceChunksAPI from '../../api/wgpt/botSourceChunks';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getSourceChunks($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getSourceChunk: $state => chunkId => {
    const [chunk] = $state.records.filter(
      record => record.id === Number(chunkId)
    );
    return chunk || {};
  },
};

export const actions = {
  get: async ({ commit }, { botId, sourceId }) => {
    commit(types.EMPTY_WGPT_BOT_SOURCE_CHUNKS);
    commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isFetching: true });
    try {
      const wgptBotSourceChunksAPI = new WgptBotSourceChunksAPI(
        botId,
        sourceId
      );
      const response = await wgptBotSourceChunksAPI.get();
      commit(types.SET_WGPT_BOT_SOURCE_CHUNKS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { botId, sourceId, ...createObj }) => {
    commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isCreating: true });
    try {
      const wgptBotSourceChunksAPI = new WgptBotSourceChunksAPI(
        botId,
        sourceId
      );
      const response = await wgptBotSourceChunksAPI.create(createObj);
      commit(types.ADD_WGPT_BOT_SOURCE_CHUNK, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { botId, sourceId, id, ...updateObj }) => {
    commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isUpdating: true });
    try {
      const wgptBotSourceChunksAPI = new WgptBotSourceChunksAPI(
        botId,
        sourceId
      );
      const response = await wgptBotSourceChunksAPI.update(id, updateObj);
      commit(types.EDIT_WGPT_BOT_SOURCE_CHUNK, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { botId, sourceId, id }) => {
    commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isDeleting: true });
    try {
      const wgptBotSourceChunksAPI = new WgptBotSourceChunksAPI(
        botId,
        sourceId
      );
      await wgptBotSourceChunksAPI.delete(id);
      commit(types.DELETE_WGPT_BOT_SOURCE_CHUNK, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { botId, sourceId, id }) => {
    commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, { isFetchingItem: true });
    try {
      const wgptBotSourceChunksAPI = new WgptBotSourceChunksAPI(
        botId,
        sourceId
      );
      const { data } = await wgptBotSourceChunksAPI.show(id);
      commit(types.ADD_WGPT_BOT_SOURCE_CHUNK, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_WGPT_BOT_SOURCE_CHUNK_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.EMPTY_WGPT_BOT_SOURCE_CHUNKS]($state) {
    $state.records = [];
  },
  [types.ADD_WGPT_BOT_SOURCE_CHUNK]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOT_SOURCE_CHUNKS]: MutationHelpers.set,
  [types.EDIT_WGPT_BOT_SOURCE_CHUNK]: MutationHelpers.update,
  [types.DELETE_WGPT_BOT_SOURCE_CHUNK]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
