import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import Vue from 'vue';
import types from '../mutation-types';
import BoardListsAPI from '../../api/wgpt/boardLists';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getLists($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getList: $state => boardId => {
    const board = $state.records.find(record => record.id === Number(boardId));
    return board || {};
  },
};

export const actions = {
  get: async ({ commit }, boardId) => {
    commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isFetching: true });
    try {
      const boardListsAPI = new BoardListsAPI(boardId);
      const response = await boardListsAPI.get();
      commit(types.SET_WGPT_BOARD_LISTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { boardId, ...listObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isCreating: true });
    try {
      const boardListsAPI = new BoardListsAPI(boardId);
      const response = await boardListsAPI.create(listObj);
      commit(types.ADD_WGPT_BOARD_LIST, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { boardId, id, ...listObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isUpdating: true });
    try {
      const boardListsAPI = new BoardListsAPI(boardId);
      const response = await boardListsAPI.update(id, listObj);
      commit(types.EDIT_WGPT_BOARD_LIST, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { boardId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isDeleting: true });
    try {
      const boardListsAPI = new BoardListsAPI(boardId);
      await boardListsAPI.delete(id);
      commit(types.DELETE_WGPT_BOARD_LIST, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { boardId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isFetchingItem: true });
    try {
      const boardListsAPI = new BoardListsAPI(boardId);
      const { data } = await boardListsAPI.show(id);
      commit(types.ADD_WGPT_BOARD_LIST, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_UI_FLAG, { isFetchingItem: false });
    }
  },
  clear: ({ commit }) => {
    commit(types.EMPTY_WGPT_BOARD_LIST);
  },
  addBoardList({ commit }, data) {
    commit(types.ADD_WGPT_BOARD_LIST, data);
  },
  updateBoardList({ commit }, data) {
    commit(types.EDIT_WGPT_BOARD_LIST, data);
  },
  deleteBoardList({ commit }, id) {
    commit(types.DELETE_WGPT_BOARD_LIST, id);
  },
};

export const mutations = {
  [types.SET_WGPT_BOARD_LIST_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.EMPTY_WGPT_BOARD_LIST]($state) {
    $state.records = [];
  },
  [types.ADD_WGPT_BOARD_LIST]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOARD_LISTS]: MutationHelpers.set,
  [types.EDIT_WGPT_BOARD_LIST]($state, data) {
    const dataBefore = $state.records.find((record, index) => {
      if (record.id !== data.id) return false;
      Vue.set($state.records, index, data);
      return true;
    });
    if (!dataBefore) return;
    if (data.order !== dataBefore.order) {
      if (data.order > dataBefore.order) {
        $state.records.forEach((record, index) => {
          if (record.id === data.id) return;
          if (record.order > dataBefore.order && record.order <= data.order) {
            record.order -= 1;
            Vue.set(state.records, index, record);
          }
        });
      } else if (data.order < dataBefore.order) {
        $state.records.forEach((record, index) => {
          if (record.id === data.id) return;
          if (record.order >= data.order && record.order < dataBefore.order) {
            record.order += 1;
            Vue.set(state.records, index, record);
          }
        });
      }
    }
    $state.records = $state.records.sort(
      (recordA, recordB) => recordA.order - recordB.order
    );
  },
  [types.DELETE_WGPT_BOARD_LIST]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
