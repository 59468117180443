import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import Vue from 'vue';
import types from '../mutation-types';
import BoardListCardsAPI from '../../api/wgpt/boardListCards';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getCards: $state => (boardId, listId) => {
    return $state.records.filter(
      record =>
        record.boardId === Number(boardId) && record.listId === Number(listId)
    );
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getCard: $state => (boardId, listId, cardId) => {
    return $state.records.find(
      record =>
        record.boardId === Number(boardId) &&
        record.listId === Number(listId) &&
        record.id === cardId
    );
  },
};

export const actions = {
  get: async ({ commit }, { boardId, listId }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetching: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const response = await boardListCardsAPI.get();
      const data = response.data.map(({ board_id, list_id, ...card }) => {
        card.boardId = board_id;
        card.listId = list_id;
        return card;
      });
      commit(types.SET_WGPT_BOARD_LIST_CARDS, { listId, data });
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { boardId, listId, ...cardObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isCreating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const {
        data: { board_id, list_id, ...data },
      } = await boardListCardsAPI.create(cardObj);
      data.boardId = board_id;
      data.listId = list_id;
      commit(types.ADD_WGPT_BOARD_LIST_CARD, data);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { boardId, listId, id, ...cardObj }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const {
        data: { board_id, list_id, ...data },
      } = await boardListCardsAPI.update(id, cardObj);
      data.boardId = board_id;
      data.listId = list_id;
      commit(types.EDIT_WGPT_BOARD_LIST_CARD, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: false });
    }
  },
  updateLabels: async ({ commit }, { boardId, listId, id, labels }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const { data } = await boardListCardsAPI.updateLabels(id, labels);
      const card = {
        id: Number(id),
        boardId: Number(boardId),
        listId: Number(listId),
        labels: data.payload,
      };
      commit(types.EDIT_WGPT_BOARD_LIST_CARD, card);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { boardId, listId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isDeleting: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      await boardListCardsAPI.delete(id);
      commit(types.DELETE_WGPT_BOARD_LIST_CARD, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { boardId, listId, id }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetchingItem: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const {
        data: { board_id, list_id, ...data },
      } = await boardListCardsAPI.show(id);
      data.boardId = board_id;
      data.listId = list_id;
      commit(types.ADD_WGPT_BOARD_LIST_CARD, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isFetchingItem: false });
    }
  },
  clear: ({ commit }) => {
    commit(types.EMPTY_WGPT_BOARD_LIST_CARD);
  },
  setBoardCards({ commit }, { listId, data }) {
    commit(types.SET_WGPT_BOARD_LIST_CARDS, { listId, data });
  },
  addBoardCard({ commit }, data) {
    commit(types.ADD_WGPT_BOARD_LIST_CARD, data);
  },
  updateBoardCard({ commit }, data) {
    commit(types.EDIT_WGPT_BOARD_LIST_CARD, data);
  },
  deleteBoardCard({ commit }, { id }) {
    commit(types.DELETE_WGPT_BOARD_LIST_CARD, Number(id));
  },
  deleteBoardCardByOriginalId({ commit }, { originalId }) {
    commit(
      types.DELETE_WGPT_BOARD_LIST_CARD_BY_ORIGINAL_ID,
      Number(originalId)
    );
  },
  addAttachment: async ({ commit }, { boardId, listId, id, file }) => {
    commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: true });
    try {
      const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await boardListCardsAPI.addAttachment(id, formData);
      const { attachments } = data;
      commit(types.ADD_WGPT_BOARD_LIST_CARD_ATTACHMENT, {
        cardId: id,
        attachments,
      });
      return attachments[attachments.length - 1];
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG, { isUpdating: false });
    }
  },
  removeAttachment: async ({ commit }, { boardId, listId, cardId, id }) => {
    const boardListCardsAPI = new BoardListCardsAPI(boardId, listId);
    await boardListCardsAPI.removeAttachment(cardId, id);
    commit(types.DELETE_WGPT_BOARD_LIST_CARD_ATTACHMENT, { cardId, id });
  },
};

export const mutations = {
  [types.SET_WGPT_BOARD_LIST_CARD_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.EMPTY_WGPT_BOARD_LIST_CARD]($state) {
    $state.records = [];
  },
  [types.ADD_WGPT_BOARD_LIST_CARD]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOARD_LIST_CARDS]($state, { listId, data }) {
    listId = Number(listId);
    const ids = data.map(({ id }) => id);
    $state.records = $state.records
      .filter(record => {
        if (ids.includes(record.id)) return false;
        if (record.listId === listId) return false;
        return true;
      })
      .concat(
        ...data.map(d => {
          d.listIdBefore = d.listId;
          d.listId = listId;
          return d;
        })
      );
  },
  [types.EDIT_WGPT_BOARD_LIST_CARD]($state, data) {
    const dataBefore = $state.records.find((record, index) => {
      if (record.id !== data.id) return false;
      Vue.set($state.records, index, { ...record, ...data });
      return true;
    });

    if (!dataBefore) {
      // This case to cover when the card is moved to the board
      MutationHelpers.setSingleRecord($state, data);
      return;
    }

    if (
      dataBefore.listIdBefore
        ? data.listId !== dataBefore.listIdBefore
        : data.listId !== dataBefore.listId
    ) {
      $state.records.forEach((record, index) => {
        if (record.listId !== data.listId) return;
        if (record.id === data.id) return;
        if (record.order >= data.order) {
          record.order += 1;
          Vue.set(state.records, index, record);
        }
      });
    } else if (data.order !== dataBefore.order) {
      if (data.order > dataBefore.order) {
        $state.records.forEach((record, index) => {
          if (record.listId !== data.listId) return;
          if (record.id === data.id) return;
          if (record.order > dataBefore.order && record.order <= data.order) {
            record.order -= 1;
            Vue.set(state.records, index, record);
          }
        });
      } else if (data.order < dataBefore.order) {
        $state.records.forEach((record, index) => {
          if (record.listId !== data.listId) return;
          if (record.id === data.id) return;
          if (record.order >= data.order && record.order < dataBefore.order) {
            record.order += 1;
            Vue.set(state.records, index, record);
          }
        });
      }
    }
    $state.records = $state.records.sort(
      (recordA, recordB) => recordA.order - recordB.order
    );
  },
  [types.DELETE_WGPT_BOARD_LIST_CARD]: MutationHelpers.destroy,
  [types.DELETE_WGPT_BOARD_LIST_CARD_BY_ORIGINAL_ID]($state, originalId) {
    $state.records = $state.records.filter(
      record => record.original_id !== originalId
    );
  },
  [types.ADD_WGPT_BOARD_LIST_CARD_ATTACHMENT]($state, { cardId, attachments }) {
    $state.records.forEach((record, index) => {
      if (record.id === cardId) {
        Vue.set($state.records, index, { ...record, attachments });
      }
    });
  },
  [types.DELETE_WGPT_BOARD_LIST_CARD_ATTACHMENT]($state, { cardId, id }) {
    $state.records.forEach((record, index) => {
      if (record.id === cardId) {
        const attachmentsBefore = record.attachments || [];
        const attachments = attachmentsBefore.filter(
          attachment => attachment.id !== id
        );
        Vue.set($state.records, index, { ...record, attachments });
      }
    });
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
