import ApiClient from '../ApiClient';

class WgptBotSourceChunksAPI extends ApiClient {
  constructor(botId, sourceId) {
    super(`bots/${botId}/sources/${sourceId}/chunks`, {
      accountScoped: true,
      wgpt: true,
    });
  }
}

export default WgptBotSourceChunksAPI;
