import ApiClient from '../ApiClient';

class WgptBotWorkflowStagesAPI extends ApiClient {
  constructor(botId, workflowId) {
    super(`bots/${botId}/workflows/${workflowId}/stages`, {
      accountScoped: true,
      wgpt: true,
    });
  }
}

export default WgptBotWorkflowStagesAPI;
