<script setup>
import { computed } from 'vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { dateFormat } from 'shared/helpers/timeHelper';
import { useI18n } from 'dashboard/composables/useI18n';

const props = defineProps({
  bot: {
    type: Object,
    required: true,
  },
});

defineEmits(['delete']);
const { t } = useI18n();

const createdByName = computed(() => {
  const createdBy = props.bot.created_by;
  if (!createdBy) return t('APP_GLOBAL.DELETED_USER');
  return createdBy.available_name ?? createdBy.email ?? '';
});

const updatedByName = computed(() => {
  const updatedBy = props.bot.updated_by;
  if (!updatedBy) return t('APP_GLOBAL.DELETED_USER');
  return updatedBy.available_name ?? updatedBy.email ?? '';
});

const updatedAt = computed(() => {
  return dateFormat(
    new Date(props.bot.updated_at).getTime() / 1000,
    'MMM d, yyyy hh:mm'
  );
});
</script>

<template>
  <tr>
    <td class="py-4 ltr:pr-4 rtl:pl-4 truncate">{{ bot.name }}</td>
    <td class="py-4 ltr:pr-4 rtl:pl-4">
      <div class="flex items-center">
        <Thumbnail :username="createdByName" size="24px" />
        <span class="mx-2">{{ createdByName }}</span>
      </div>
    </td>
    <td class="py-4 ltr:pr-4 rtl:pl-4">
      <div class="flex items-center">
        <Thumbnail :username="updatedByName" size="24px" />
        <span class="mx-2">{{ updatedByName }}</span>
      </div>
    </td>

    <td>{{ updatedAt }}</td>

    <td class="py-4 flex justify-end gap-1">
      <router-link :to="{ name: 'wgpt_bots_edit', params: { botId: bot.id } }">
        <woot-button
          v-tooltip.top="$t('AGENT_BOTS.EDIT.BUTTON_TEXT')"
          variant="smooth"
          size="tiny"
          color-scheme="secondary"
          class-names="grey-btn"
          icon="settings"
        />
      </router-link>
      <woot-button
        v-tooltip.top="$t('AGENT_BOTS.DELETE.BUTTON_TEXT')"
        variant="smooth"
        color-scheme="alert"
        size="tiny"
        icon="dismiss-circle"
        class-names="grey-btn"
        @click="$emit('delete')"
      />
    </td>
  </tr>
</template>
