import WgptBots from './Index.vue';
// import Editor from './Editor';
const CreateStepWrap = () => import('./create/Index.vue');
const CreateBot = () => import('./create/CreateBot.vue');
const AssignInboxes = () => import('./create/AssignInboxes.vue');
const FinishSetup = () => import('./create/FinishSetup.vue');
const Editor = () => import('./edit/Index.vue');

import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const SettingsWrapper = () => import('../SettingsWrapper.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/wgpt-bots'),
      component: SettingsWrapper,
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'wgpt_bots_list',
          component: WgptBots,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/wgpt-bots'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'WGPT_BOTS.HEADER',
          headerButtonText: 'WGPT_BOTS.HEADER_BTN_TXT',
          icon: 'bot',
          newButtonRoutes: ['wgpt_bots_new'],
          showBackButton: true,
        };
      },
      children: [
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'wgpt_bots_new',
              component: CreateBot,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: ':botId/inboxes',
              name: 'wgpt_bots_add_inboxes',
              component: AssignInboxes,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: ':teamId/finish',
              name: 'wgpt_bots_finish',
              component: FinishSetup,
              meta: {
                permissions: ['administrator'],
              },
            },
          ],
        },
        {
          path: ':botId/edit',
          name: 'wgpt_bots_edit',
          component: Editor,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
