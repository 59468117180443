import ApiClient from '../ApiClient';

class WgptBoardListCardActivitiesAPI extends ApiClient {
  constructor(boardId, listId, cardId) {
    super(`boards/${boardId}/lists/${listId}/cards/${cardId}/activities`, {
      accountScoped: true,
      wgpt: true,
    });
  }
}

export default WgptBoardListCardActivitiesAPI;
