/* global axios */
import ApiClient from '../ApiClient';

class WgptInboxesAPI extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true, wgpt: true });
  }

  getBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/bot`);
  }

  setBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_bot`, {
      wgpt_bot: botId,
    });
  }
}

export default new WgptInboxesAPI();
