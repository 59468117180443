/* global axios */
import ApiClient from '../ApiClient';

class WgptBoardsAPI extends ApiClient {
  constructor() {
    super('boards', { accountScoped: true, wgpt: true });
  }

  search({ id, q }) {
    return axios.get(`${this.url}/${id}/search_cards`, {
      params: {
        q,
      },
    });
  }
}

export default new WgptBoardsAPI();
