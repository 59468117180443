import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import WgptBoardsAPI from '../../api/wgpt/boards';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';

const state = {
  records: [],
  searchedRecords: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isSearching: false,
  },
};

export const getters = {
  getBoards($state) {
    return $state.records;
  },
  getSearchedCards($state) {
    return $state.searchedRecords;
  },
  getBoard: $state => boardId => {
    const [board] = $state.records.filter(
      record => record.id === Number(boardId)
    );
    return board || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_WGPT_BOARD_UI_FLAG, { isFetching: true });
    try {
      const response = await WgptBoardsAPI.get();
      commit(types.SET_WGPT_BOARDS, response.data);
      return response.data;
    } catch (error) {
      // Ignore error
      return undefined;
    } finally {
      commit(types.SET_WGPT_BOARD_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, boardObj) => {
    commit(types.SET_WGPT_BOARD_UI_FLAG, { isCreating: true });
    try {
      const response = await WgptBoardsAPI.create(boardObj);
      commit(types.ADD_WGPT_BOARD, response.data);
      return response.data;
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_WGPT_BOARD_UI_FLAG, { isUpdating: true });
    try {
      const response = await WgptBoardsAPI.update(id, updateObj);
      commit(types.EDIT_WGPT_BOARD, response.data);
      return response.data;
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_WGPT_BOARD_UI_FLAG, { isDeleting: true });
    try {
      await WgptBoardsAPI.delete(id);
      commit(types.DELETE_WGPT_BOARD, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_WGPT_BOARD_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await WgptBoardsAPI.show(id);
      commit(types.ADD_WGPT_BOARD, data);
      return data;
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARD_UI_FLAG, { isFetchingItem: false });
    }
  },
  async search({ commit }, { id, q }) {
    commit(types.SEARCH_WGPT_BOARD_CARDS, []);
    commit(types.SET_WGPT_BOARD_UI_FLAG, { isSearching: true });
    try {
      const response = await WgptBoardsAPI.search({ id, q });
      commit(types.SEARCH_WGPT_BOARD_CARDS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOARD_UI_FLAG, { isSearching: false });
    }
  },
  addBoard({ commit }, board) {
    commit(types.ADD_WGPT_BOARD, board);
  },
  updateBoard({ commit }, board) {
    commit(types.EDIT_WGPT_BOARD, board);
  },
  deleteBoard({ commit }, { id }) {
    commit(types.DELETE_WGPT_BOARD, id);
  },
};

export const mutations = {
  [types.SET_WGPT_BOARD_UI_FLAG]($state, data) {
    $state.uiFlags = { ...$state.uiFlags, ...data };
  },
  [types.ADD_WGPT_BOARD]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOARDS]: MutationHelpers.set,
  [types.EDIT_WGPT_BOARD]: MutationHelpers.update,
  [types.DELETE_WGPT_BOARD]: MutationHelpers.destroy,
  [types.SEARCH_WGPT_BOARD_CARDS]($state, records) {
    $state.searchedRecords = records;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
