export default {
  data() {
    return {
      wpgtNotificationsHandler: {
        wgpt_board_list_card_updated: notification => {
          const {
            primary_actor: card,
            meta: { board_id: boardId },
          } = notification;
          this.$router.push({
            name: 'board_item',
            params: {
              boardId: boardId,
            },
            query: {
              selectedCard: card?.id,
            },
          });
        },
        wgpt_board_list_card_activity_created: notification => {
          const {
            primary_actor: card,
            meta: { board_id: boardId },
          } = notification;
          this.$router.push({
            name: 'board_item',
            params: {
              boardId: boardId,
            },
            query: {
              selectedCard: card?.id,
            },
          });
        },
        wgpt_board_list_card_activity_updated: notification => {
          const {
            primary_actor: card,
            meta: { board_id: boardId },
          } = notification;
          this.$router.push({
            name: 'board_item',
            params: {
              boardId: boardId,
            },
            query: {
              selectedCard: card?.id,
            },
          });
        },
        wgpt_board_list_card_activity_deleted: notification => {
          const {
            primary_actor: card,
            meta: { board_id: boardId },
          } = notification;
          this.$router.push({
            name: 'board_item',
            params: {
              boardId: boardId,
            },
            query: {
              selectedCard: card?.id,
            },
          });
        },
      },
    };
  },
};
