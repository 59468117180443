import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import BotsAPI from '../../api/wgpt/bots';
import InboxesAPI from '../../api/wgpt/inboxes';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  searchResults: [],
  searchMeta: {},
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isDeletingAvatar: false,
    isUpdating: false,
    isFetchingBot: false,
    isSettingBot: false,
    isDisconnecting: false,
    isSearching: false,
    isSearchCompleted: false,
  },
  botInbox: {},
};

const buildBotFormData = botObj => {
  const formData = new FormData();
  const { pre_chat_options = {}, config = {}, ...botProperties } = botObj;
  Object.keys(botProperties).forEach(key => {
    if (botProperties[key]) {
      formData.append(key, botProperties[key]);
    }
  });
  const { fields = {}, ...preChatOptionsProperties } = pre_chat_options;
  Object.keys(preChatOptionsProperties).forEach(key => {
    formData.append(`pre_chat_options[${key}]`, preChatOptionsProperties[key]);
  });
  Object.keys(fields).forEach(key => {
    formData.append(`pre_chat_options[fields][${key}]`, fields[key]);
  });

  Object.keys(config).forEach(key => {
    formData.append(`config[${key}]`, config[key]);
  });

  return formData;
};

export const getters = {
  getBots($state) {
    return $state.records;
  },
  getSearchResults($state) {
    return $state.searchResults;
  },
  getSearchMeta: $state => {
    return $state.searchMeta;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getBot: $state => botId => {
    const [bot] = $state.records.filter(record => record.id === Number(botId));
    return bot || {};
  },
  getActiveBot: $state => inboxId => {
    const associatedBotId = $state.botInbox[Number(inboxId)];
    return getters.getBot($state)(associatedBotId);
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isFetching: true });
    try {
      const response = await BotsAPI.get();
      commit(types.SET_WGPT_BOTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, botObj) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isCreating: true });
    try {
      const response = await BotsAPI.create(botObj);
      commit(types.ADD_WGPT_BOT, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, isFormData = false, ...botObj }) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isUpdating: true });
    try {
      const response = await BotsAPI.update(
        id,
        isFormData ? buildBotFormData(botObj) : botObj
      );
      commit(types.EDIT_WGPT_BOT, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isDeleting: true });
    try {
      await BotsAPI.delete(id);
      commit(types.DELETE_WGPT_BOT, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await BotsAPI.show(id);
      commit(types.ADD_WGPT_BOT, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isFetchingItem: false });
    }
  },
  async search({ commit }, { id: botId, ...params }) {
    commit(types.SET_WGPT_BOT_UI_FLAG, {
      isSearching: true,
      isSearchCompleted: false,
    });
    try {
      const response = await BotsAPI.search({ botId, ...params });
      commit(types.SEARCH_WGPT_BOT_CHUNKS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, {
        isSearching: false,
        isSearchCompleted: true,
      });
    }
  },
  clearSearch({ commit }) {
    commit(types.EMPTY_SEARCH_WGPT_BOT_CHUNKS);
  },
  deleteAvatar: async ({ commit }, id) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isDeletingAvatar: true });
    try {
      await BotsAPI.deleteAvatar(id);
      commit(types.DELETE_WGPT_BOT_AVATAR, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isDeletingAvatar: false });
    }
  },
  fetchBotInbox: async ({ commit }, inboxId) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isFetchingBot: true });
    try {
      const { data } = await InboxesAPI.getBot(inboxId);
      const { wgpt_bot: wgptBot = {} } = data || {};
      commit(types.SET_WGPT_BOT_INBOX, { botId: wgptBot.id, inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isFetchingBot: false });
    }
  },
  setBotInbox: async ({ commit }, { inboxId, botId }) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isSettingBot: true });
    try {
      await InboxesAPI.setBot(inboxId, botId);
      commit(types.SET_WGPT_BOT_INBOX, { botId: botId, inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isSettingBot: false });
    }
  },
  disconnectBot: async ({ commit }, { inboxId }) => {
    commit(types.SET_WGPT_BOT_UI_FLAG, { isDisconnecting: true });
    try {
      await InboxesAPI.setBot(inboxId, null);
      commit(types.SET_WGPT_BOT_INBOX, { botId: '', inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_UI_FLAG, { isDisconnecting: false });
    }
  },
};

export const mutations = {
  [types.SET_WGPT_BOT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_WGPT_BOT]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOTS]: MutationHelpers.set,
  [types.EDIT_WGPT_BOT]: MutationHelpers.update,
  [types.DELETE_WGPT_BOT]: MutationHelpers.destroy,
  [types.SET_WGPT_BOT_INBOX]($state, { botId, inboxId }) {
    Vue.set($state.botInbox, inboxId, botId);
  },
  [types.SEARCH_WGPT_BOT_CHUNKS]($state, data) {
    $state.searchResults = data.payload;
    $state.searchMeta = data.meta;
  },
  [types.EMPTY_SEARCH_WGPT_BOT_CHUNKS]($state) {
    $state.searchResults = [];
    $state.searchMeta = {};
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
