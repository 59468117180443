/* global axios */
import ApiClient from '../ApiClient';

class WgptBotsAPI extends ApiClient {
  constructor() {
    super('bots', { accountScoped: true, wgpt: true });
  }

  deleteAvatar(botId) {
    return axios.delete(`${this.url}/${botId}/avatar`);
  }

  search({ botId, ...params }) {
    return axios.get(`${this.url}/${botId}/search_chunks`, {
      params,
    });
  }
}

export default new WgptBotsAPI();
