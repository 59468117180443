import { frontendURL } from '../../../helper/URLHelper';
const BoardsHome = () => import('./overview/Index.vue');
const Board = () => import('./item/Index.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/wgpt-boards'),
      component: Index,
      children: [
        {
          path: '',
          name: 'boards_overview',
          component: BoardsHome,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
        {
          path: ':boardId',
          name: 'board_item',
          component: Board,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
      ],
      props: route => {
        return {
          showSearchbox: route.name === 'board_item',
          headerTitle:
            route.name === 'boards_overview'
              ? 'WGPT_BOARDS.OVERVIEW.HEADER'
              : '',
        };
      },
    },
  ],
};
