/* global axios */
import ApiClient from '../ApiClient';

class WgptAccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true, wgpt: true });
  }

  checkout() {
    return axios.post(`${this.url}checkout`);
  }

  getLimits() {
    return axios.get(`${this.url}limits`);
  }
}

export default new WgptAccountAPI();
