import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import WgptBotWorkflowsAPI from '../../api/wgpt/botWorkflows';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getWorkflows($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getWorkflow: $state => workflowId => {
    const [workflow] = $state.records.filter(
      record => record.id === Number(workflowId)
    );
    return workflow || {};
  },
};

export const actions = {
  get: async ({ commit }, botId) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isFetching: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowsAPI(botId);
      const response = await wgptBotWorkflowsAPI.get();
      commit(types.SET_WGPT_BOT_WORKFLOWS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { botId, ...botObj }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isCreating: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowsAPI(botId);
      const response = await wgptBotWorkflowsAPI.create(botObj);
      commit(types.ADD_WGPT_BOT_WORKFLOW, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { botId, id, ...updateObj }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isUpdating: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowsAPI(botId);
      const response = await wgptBotWorkflowsAPI.update(id, updateObj);
      commit(types.EDIT_WGPT_BOT_WORKFLOW, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, { botId, id }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isDeleting: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowsAPI(botId);
      await wgptBotWorkflowsAPI.delete(id);
      commit(types.DELETE_WGPT_BOT_WORKFLOW, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, { botId, id }) => {
    commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isFetchingItem: true });
    try {
      const wgptBotWorkflowsAPI = new WgptBotWorkflowsAPI(botId);
      const { data } = await wgptBotWorkflowsAPI.show(id);
      commit(types.ADD_WGPT_BOT_WORKFLOW, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOT_WORKFLOW_UI_FLAG, { isFetchingItem: false });
    }
  },
};

export const mutations = {
  [types.SET_WGPT_BOT_WORKFLOW_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_WGPT_BOT_WORKFLOW]: MutationHelpers.setSingleRecord,
  [types.SET_WGPT_BOT_WORKFLOWS]: MutationHelpers.set,
  [types.EDIT_WGPT_BOT_WORKFLOW]: MutationHelpers.update,
  [types.DELETE_WGPT_BOT_WORKFLOW]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
